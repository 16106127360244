import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, IconButton, Stack, Typography, styled } from '@mui/material';
import TuneBlockImage from './tuneBlock/TuneBlockImage';
import FullSizeImage from './fullSizeImage/FullSizeImage';
import TunesContext from '../../../../context/TunesContext/TunesContext';
import ProjectsContext from '../../../../context/ProjectsContext/ProjectsContext';
import { useLocalStorage } from '@root/context/LocalStorageContext/useLocalStorage';
import { ExpandSectionIcon } from '@assets/icons/ExpandSectionIcon';
import { ExpandMenuIcon } from '@root/assets/icons/ExpandMenuIcon';
import { TuneImageDto } from '@root/types/dto';
import { Image } from '@root/utils/image/image';
import { ControlImage } from '@root/utils/tune/ControlImages';
import { debounce } from 'lodash';
import FullScreenSection from './FullScreenSection/FullScreenSection';

const DEFAULT_CONTAINER_SIZE = 220;
const DEFAULT_PADDING = 50;
const DEBOUNCE_TIME = 100;

const FavoriteSection = (): React.JSX.Element => {
    const {
        likeImage,
        deleteImage,
        getImageById,
        setIsFullScreenMode,
        isFullScreenMode,
        setIsFavoriteSectionActive,
        activeImageSrc,
        setActiveImageSrc,
    } = useContext(TunesContext);

    const { likedImages: images, setProjectImagesNumber } = useContext(ProjectsContext);
    const { favoriteVisibility, setFavoriteVisibility } = useLocalStorage();

    const observer = new ResizeObserver(() => resizeViewport());

    const contentRef = useRef<HTMLDivElement>(null);

    const [isVisible, setVisible] = useState<boolean>(favoriteVisibility === 'true');
    const [fullSize, setFullSize] = useState<boolean>(false);
    const [showScrollbar, setShowScrollbar] = useState<boolean>(false);

    const [imagesConfig, setImagesConfig] = useState<any[]>([]);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [controlImage, setControlImage] = useState<ControlImage | null>(null);
    const [scrollHeight, setScrollHeight] = useState<number | null>(null);

    const handleSetVisible = (): void => {
        setVisible(!isVisible);
        setFavoriteVisibility((!isVisible).toString());

        if (fullSize) {
            handleSectionFullSize();
        }
    };

    const handleCloseFullSize = (): void => {
        setActiveIndex(null);
    };

    const likeActiveImage = async (id: number | string): Promise<void> => {
        if (activeIndex) {
            handleCloseFullSize();
        }

        const image = images.find((el: TuneImageDto) => el.Id === id);
        await likeImage(image);
    };

    const getConfigurationArray = (): void => {
        const config = images.map((el: TuneImageDto) => {
            return JSON.parse(el.Configurations);
        });
        setImagesConfig(config);
    };

    const handleSectionFullSize = (): void => {
        if (!contentRef.current) {
            return;
        }

        setFullSize(!fullSize);
        fullSize ? setShowScrollbar(false) : setTimeout(() => setShowScrollbar(true), 400);

        contentRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    const handlePrevious = (): void => {
        setActiveImageSrc('');

        if (activeIndex === null) {
            return;
        }
        setActiveIndex(Math.max(activeIndex - 1, 0));
    };

    const handleNext = (): void => {
        setActiveImageSrc('');

        if (activeIndex === null) {
            return;
        }
        setActiveIndex(Math.min(activeIndex + 1, imagesConfig.length - 1));
    };

    const handleDeleteActiveImage = async (): Promise<void> => {
        if (activeIndex === null) {
            return;
        }

        if (images?.length) {
            const activeImageId = images[activeIndex].Id;
            const success = await deleteImage(activeImageId);
            if (success?.projectImagesNumber) {
                setProjectImagesNumber(success.projectImagesNumber);
                const nextActiveImageIndex = activeIndex === images.length - 1 ? activeIndex - 1 : activeIndex;
                setActiveIndex(nextActiveImageIndex);
            }
        }
    };

    const toggleFullSizeMode = (): void => {
        if (activeIndex === null) {
            return;
        }

        setIsFavoriteSectionActive(true);
        setIsFullScreenMode(true);
    };

    const resizeViewport = debounce(() => {
        if (!contentRef.current) {
            return;
        }

        setScrollHeight(contentRef.current?.scrollHeight);
    }, DEBOUNCE_TIME);

    useEffect(() => {
        if (images?.length) {
            getConfigurationArray();
        }
    }, [images]);

    useEffect(() => {
        if (contentRef.current) {
            observer.observe(contentRef.current);
            setScrollHeight(contentRef.current?.scrollHeight);
        }

        return () => {
            observer.disconnect();
        };
    }, [contentRef.current]);

    useEffect(() => {
        if (activeIndex === null) {
            return;
        }

        const controlImageId = imagesConfig[activeIndex]?.control_image_id;
        if (controlImageId >= 0) {
            getImageById(controlImageId).then((image: Image) => {
                setControlImage({
                    path: image.imageUrl,
                    influence: imagesConfig[activeIndex].control_depth_weight,
                });
            });
        } else {
            setControlImage(null);
        }
    }, [activeIndex, imagesConfig]);

    return images?.length ? (
        <Wrapper>
            <ButtonWrapper expanded={+isVisible}>
                <ButtonLabel variant="subtitle1">{'Favorite'}</ButtonLabel>
                <ExpandButtonIcon
                    expanded={+isVisible}
                    disableRipple
                    onClick={handleSetVisible}
                >
                    <ExpandIcon></ExpandIcon>
                </ExpandButtonIcon>
            </ButtonWrapper>

            <ContentWrapper
                visible={+isVisible}
                height={
                    fullSize && contentRef.current
                        ? (scrollHeight || contentRef.current.scrollHeight) + DEFAULT_PADDING
                        : DEFAULT_CONTAINER_SIZE
                }
            >
                <Content
                    isOverflow={fullSize}
                    scroll={showScrollbar}
                    ref={contentRef}
                >
                    {images.map((item: TuneImageDto, i: number) => {
                        return (
                            <TuneBlockImage
                                key={i}
                                item={item}
                                setActiveIndex={() => setActiveIndex(i)}
                                likeImage={likeActiveImage}
                            />
                        );
                    })}
                </Content>

                {activeIndex !== null && (
                    <FullSizeImage
                        toggleFullSizeMode={toggleFullSizeMode}
                        onClose={handleCloseFullSize}
                        configuration={imagesConfig[activeIndex]}
                        activeImage={images[activeIndex]}
                        likeImage={likeActiveImage}
                        onPrevious={activeIndex > 0 ? handlePrevious : null}
                        onNext={activeIndex < imagesConfig.length - 1 ? handleNext : null}
                        onDelete={handleDeleteActiveImage}
                        controlDepthImage={imagesConfig[activeIndex]?.enable_depth_control ? controlImage : undefined}
                        controlSketchImage={imagesConfig[activeIndex]?.enable_sketch_control ? controlImage : undefined}
                    />
                )}
                {activeIndex !== null && (
                    <FullScreenSection
                        isFullScreenActive={isFullScreenMode}
                        setIsFullScreenActive={setIsFullScreenMode}
                        moveToNextImage={handleNext}
                        moveToPreviousImage={handlePrevious}
                        activeImageUrl={activeImageSrc ? activeImageSrc : images[activeIndex].imageUrl}
                    ></FullScreenSection>
                )}
                <ExpandSectionButton
                    onClick={handleSectionFullSize}
                    fullSize={fullSize}
                    display={!isVisible ? 'none' : scrollHeight && scrollHeight > DEFAULT_CONTAINER_SIZE ? 'inline-flex' : 'none'}
                >
                    <ExpandSectionIcon />
                </ExpandSectionButton>
            </ContentWrapper>
        </Wrapper>
    ) : (
        <></>
    );
};

export default React.memo(FavoriteSection);

const Wrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '54px',
}));

const ExpandIcon = styled(ExpandMenuIcon)(({ theme }) => ({
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
        borderRadius: '50%',

        '& path': {
            stroke: theme.palette.secondary.main,
        },
    },
}));

const ExpandButtonIcon = styled(IconButton)<{ expanded: number }>(({ expanded }) => ({
    transform: expanded ? 'rotate(180deg)' : 'none',
    transition: 'all 0.5s',

    backgroundColor: 'transparent',
    '&:hover': {
        backgroundColor: 'transparent',
    },
}));

const ButtonWrapper = styled(Stack)<{ expanded: number }>(({ expanded }) => ({
    alignSelf: 'flex-start',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    height: '44px',
    width: '155px',
    marginBottom: expanded ? '0px' : '10px',

    borderRadius: '15px',
    padding: '10px 25px 10px 35px',
    borderBottomRightRadius: expanded ? '0px' : '15px',
    borderBottomLeftRadius: expanded ? '0px' : '15px',
    boxShadow: expanded ? '0px 0px 26px 0px rgba(0,0,0,0.35)' : '0px 5px 26px 0px rgba(0,0,0,0.35)',
}));

const ContentWrapper = styled(Box)<{ visible: number; height: number }>(({ visible, height }) => ({
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    height: visible ? `${height}px` : '0px',
    gap: '20px',
    borderRadius: '15px',
    borderTopLeftRadius: '0px',
    padding: visible ? '20px 30px 23px' : '0px 30px 0px',
    marginBottom: visible ? '10px' : '0px',
    boxShadow: ' 0px 5px 26px 0px rgba(0,0,0,0.35)',

    width: '100%',
    maxHeight: '800px',

    position: 'relative',
    transition: 'all 0.5s',
    zIndex: 2,

    '@media (max-height:1000px)': {
        maxHeight: '500px',
    },

    '@media (max-height:800px)': {
        maxHeight: '400px',
    },
}));

const ButtonLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette.neutrals.inactive1,
}));

const Content = styled(Stack)<{ isOverflow: boolean; scroll: boolean }>(({ isOverflow, scroll }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignSelf: 'flex-start',
    paddingRight: '10px',

    width: '100%',
    gap: '22px',
    overflow: isOverflow ? 'auto' : 'hidden',

    '&::-webkit-scrollbar': {
        display: scroll ? 'block' : 'none',
    },
}));

const ExpandSectionButton = styled(IconButton)<{ fullSize: boolean; display: string }>(({ theme, fullSize, display }) => ({
    display: display,
    cursor: 'pointer',

    position: 'absolute',
    bottom: '-18px',
    padding: 0,

    transform: fullSize ? 'rotate(180deg)' : 'unset',
    transition: 'all 0.5s',
    zIndex: 2,

    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.inactive}`,
    backgroundColor: theme.palette.neutrals.main,

    '&:hover': {
        backgroundColor: theme.palette.neutrals.light,
    },
}));
