import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import { AspectRatioIcon } from '@root/assets/icons/AspectRatioIcon';
import { useCurate } from '@root/hooks/curate/useCurate';

const CreateNewSketchModal = ({ open, close }) => {
    const [orientation, setOrientation] = useState('landscape');
    const { createLayer, canvasImageSrc } = useCurate();
    const [options, setOptions] = useState([
        {
            id: 0,
            width: 16,
            height: 9,
            isSelected: true,
        },
        {
            id: 1,
            width: 2,
            height: 1,
            isSelected: false,
        },
        {
            id: 2,
            width: 7,
            height: 4,
            isSelected: false,
        },
        {
            id: 3,
            width: 3,
            height: 2,
            isSelected: false,
        },
        {
            id: 4,
            width: 4,
            height: 3,
            isSelected: false,
        },
        {
            id: 5,
            width: 5,
            height: 4,
            isSelected: false,
        },
        {
            id: 6,
            width: 1,
            height: 1,
            isSelected: false,
        },
    ]);

    const select = (id) => {
        setOptions((prev) =>
            prev.map((el) => {
                el.isSelected = el.id === id;
                return el;
            })
        );
    };

    const create = async () => {
        const canvas = document.createElement('canvas');

        const selection = options.find((el) => el.isSelected);
        const ratio = selection.width / selection.height;
        canvas.height = 512;

        if (orientation === 'landscape') {
            canvas.width = 512 * ratio;
        } else {
            canvas.width = canvas.height;
            canvas.height = 512 * ratio;
        }

        const ctx = canvas.getContext('2d');

        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        const image = {
            Id: null,
            Path: canvas.toDataURL('image/png'),
        };

        const layer = {
            imageUrl: image.Path,
            isVisible: !!image,
        };
        await createLayer(layer);
    };

    useEffect(() => {
        open && canvasImageSrc && close();
    }, [canvasImageSrc]);

    return (
        <Modal
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
            open={open}
            onClose={() => {
                close();
            }}
        >
            <MainWrapper>
                <Wrapper>
                    <Typography variant="body16">Aspect ratio</Typography>
                    <Stack
                        direction={'row'}
                        flexWrap={'wrap'}
                        justifyContent={'center'}
                        gap={1.2}
                    >
                        {options.map((el) => (
                            <SelectButton
                                onClick={() => select(el.id)}
                                key={el.id}
                                color="secondary"
                                variant="outlined"
                                active={el.isSelected ? 1 : 0}
                            >
                                {orientation === 'landscape' ? `${el.width}:${el.height}` : `${el.height}:${el.width}`}
                            </SelectButton>
                        ))}
                    </Stack>
                </Wrapper>
                <Wrapper>
                    <Typography variant="body16">Orientation</Typography>
                    <IconWrapper
                        direction="row"
                        gap={2.2}
                        justifyContent="space-between"
                    >
                        <AspectRatioIcon
                            onClick={() => setOrientation('landscape')}
                            color={orientation === 'landscape' ? 'primary' : ''}
                        />
                        <AspectRatioIcon
                            onClick={() => setOrientation('portrait')}
                            color={orientation === 'portrait' ? 'primary' : ''}
                        />
                    </IconWrapper>
                    <Stack
                        direction={'row'}
                        gap={2.5}
                    >
                        <CancelButton
                            onClick={close}
                            variant="contained"
                        >
                            cancel
                        </CancelButton>
                        <CreateButton
                            onClick={create}
                            variant="contained"
                        >
                            create
                        </CreateButton>
                    </Stack>
                </Wrapper>
            </MainWrapper>
        </Modal>
    );
};

export default CreateNewSketchModal;

const MainWrapper = styled(Box)(({ theme }) => ({
    width: '450px',
    height: '350px',
    background: '#292929',

    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: theme.spacing(3),

    padding: theme.spacing(1, 0),
    borderRadius: '15px',
    outline: 'none',
    boxShadow: '0px 0px 31px 0px rgba(0, 0, 0, 0.35)',
}));

const Wrapper = styled(Stack)(({ theme }) => ({
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2.3),
    padding: theme.spacing(1, 10),
}));

const IconWrapper = styled(Stack)(() => ({
    '& svg': {
        cursor: 'pointer',
    },
    '& svg:first-of-type': {
        rotate: '90deg',
    },
}));

const SelectButton = styled(Button)(({ theme, active }) => ({
    width: '44px',
    height: '28px',
    minWidth: '0px',
    fontSize: '12px',
    borderColor: active ? theme.palette.primary.main : 'inherit',
}));

const CancelButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.light1,
    color: 'black',
    width: '160px',
}));

const CreateButton = styled(Button)(() => ({
    color: 'black',
    width: '160px',
}));
