import React, { useEffect } from 'react';
import { Box, Stack, styled } from '@mui/material';

import ToolPanel from './components/ToolPanel/ToolPanel';
import CuratePageForm from './components/CuratePageForm';
import CurateLayersInfo from './components/layersPreviewComponents/CurateLayersInfo';

import { useCurate } from '@hooks/curate/useCurate';
import useKeyPress from '@hooks/helpers/useKeyPress';

import { CurateMainContent } from './components/CurateMainContent';
import FullSizeImageView from './components/FullSizeImageView';
import ImageMetadataDialog from './components/ImageMetadataDialog';
import ProgressBar from '@root/components/ProgressBar';

import { CurateTools, HotKeysList, SupportedHotKeys } from '@root/utils/constants/enums';

const CuratePage = () => {
    const {
        openLastSession,
        canvasImageSrc,
        isFullScreenActive,
        toggleFullScreen,
        moveToNextLayer,
        moveToPreviousLayer,
        toggleMetadataDialog,
        isMetadataDialogActive,
        currentLayerInfo,
        deleteLayer,
        downloadImage,
        imageMenuIsActive,
        setImageMenuIsActive,
        formMenuIsActive,
        setFormMenuIsActive,
        progressState,
        setProgressState,
        isProgressBarActive,
        canvasImageSketchSrc,
        activeTool,
        progressPercentage,
    } = useCurate();

    const showMetadataHotKey = useKeyPress(
        SupportedHotKeys[HotKeysList.CTRL_I].key,
        SupportedHotKeys[HotKeysList.CTRL_I].withCtrl,
        SupportedHotKeys[HotKeysList.CTRL_I].blockDefaultEvent
    );
    const downloadImageHotKey = useKeyPress(
        SupportedHotKeys[HotKeysList.CTRL_S].key,
        SupportedHotKeys[HotKeysList.CTRL_S].withCtrl,
        SupportedHotKeys[HotKeysList.CTRL_S].blockDefaultEvent
    );
    const moveToNextHotKey = useKeyPress(
        SupportedHotKeys[HotKeysList.CTRL_ARROW_DOWN].key,
        SupportedHotKeys[HotKeysList.CTRL_ARROW_DOWN].withCtrl,
        SupportedHotKeys[HotKeysList.CTRL_ARROW_DOWN].blockDefaultEvent
    );
    const menuActionHotKey = useKeyPress(
        SupportedHotKeys[HotKeysList.CTRL_H].key,
        SupportedHotKeys[HotKeysList.CTRL_H].withCtrl,
        SupportedHotKeys[HotKeysList.CTRL_H].blockDefaultEvent
    );

    useEffect(() => {
        openLastSession();
    }, []);

    useEffect(() => {
        if (showMetadataHotKey) {
            toggleMetadataDialog();
        }

        if (downloadImageHotKey) {
            downloadImage();
        }

        if (moveToNextHotKey) {
            moveToNextLayer();
        }
    }, [showMetadataHotKey, downloadImageHotKey, moveToNextHotKey]);

    useEffect(() => {
        if (menuActionHotKey) {
            const value = !imageMenuIsActive || !formMenuIsActive;
            setImageMenuIsActive(value);
            setFormMenuIsActive(value);
        }
    }, [menuActionHotKey]);

    return (
        <Wrapper>
            <ToolPanel />
            <ProgressBar
                externalProgressValue={progressPercentage}
                externalState={progressState}
                setExternalState={setProgressState}
                isVisible={isProgressBarActive && activeTool !== CurateTools.Eraser}
            />
            <Container>
                <LeftMenu>
                    <CuratePageForm />
                </LeftMenu>
                <CurateMainContent />
                <RightMenu>
                    <CurateLayersInfo />
                </RightMenu>
            </Container>
            <FullSizeImageView
                active={isFullScreenActive}
                imageUrl={canvasImageSrc}
                sketchImageUrl={canvasImageSketchSrc}
                handleClose={toggleFullScreen}
                moveToNext={moveToNextLayer}
                moveToPrevious={moveToPreviousLayer}
            ></FullSizeImageView>
            <ImageMetadataDialog
                active={isMetadataDialogActive}
                imageUrl={canvasImageSrc}
                sketchImageUrl={canvasImageSketchSrc}
                handleClose={toggleMetadataDialog}
                moveToNext={moveToNextLayer}
                selectedImageInfo={currentLayerInfo}
                deleteById={deleteLayer}
                downloadImage={downloadImage}
                moveToPrevious={moveToPreviousLayer}
            ></ImageMetadataDialog>
        </Wrapper>
    );
};

const CuratePageWithProvider = () => {
    return <CuratePage />;
};

export default CuratePageWithProvider;

const Wrapper = styled(Stack)(({ theme }) => ({
    width: '100%',
    height: '100%',
    position: 'relative',
    background: theme.palette.neutrals.main,
    paddingBottom: theme.spacing(2),
}));

const Container = styled(Box)(() => ({
    width: '100%',
    height: '100%',
    position: 'relative',
}));

const LeftMenu = styled(Box)(() => ({
    position: 'absolute',
    left: 0,
    zIndex: 2,
}));

const RightMenu = styled(Box)(() => ({
    position: 'absolute',
    right: 0,
    zIndex: 2,
    top: 0,
}));
