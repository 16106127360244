import React, { useEffect, useRef, useState } from 'react';
import { Box, Skeleton, styled } from '@mui/material';
import { useCurate } from '@root/hooks/curate/useCurate';

import DragAndDropFileUploader from '@root/components/DragAndDropFileUploader';
import SceneModeFooter from './SceneModeFooter';
import ConfirmationModal from './ConfirmationModal';
import CurateSceneControlPanel from './CurateSceneControlPanel';

import { MAX_FILE_SIZE_3D_VIEWER } from '@utils/constants/cameraViewConstants';
import { Model3dErrorCode, SceneViewerOption } from '@root/utils/constants/enums';
import { ImageHelpers } from '@root/utils/helpers';
import ImagePreviewBox from './ImagePreviewBox';

const CurateScene = (): React.JSX.Element => {
    const {
        isLoading,
        sceneGeneratedImageUrl,
        lastSceneSnapshot,
        loadedModel,
        addModelToScene,
        initScene,
        isSceneReady,
        recenterCamera,
        uploadedFileName,
        resetToolInfo,
        saveSceneAsImage,
        setSceneGeneratedImageUrl,
        sceneGeneratedLayerId,
        setSceneGeneratedLayerId,
        sceneViewerType,
        setSceneViewerType,
    } = useCurate();

    const sceneRef = useRef<HTMLDivElement | null>(null);

    const [confirmationModalState, setConfirmationModalState] = useState<boolean>(false);
    const [isImageLoading, setIsImageLoading] = useState<boolean>(true);
    const [errorType, setErrorType] = useState<Model3dErrorCode | null>(null);

    const handleImageLoad = (): void => {
        setIsImageLoading(false);
    };

    const handleClick = (): void => {
        if (sceneViewerType === SceneViewerOption.SCENE) {
            saveSceneAsImage();
        }
        setSceneViewerType(sceneViewerType === SceneViewerOption.IMAGE ? SceneViewerOption.SCENE : SceneViewerOption.IMAGE);
    };

    useEffect(() => {
        if (!sceneGeneratedImageUrl) {
            return;
        }

        setSceneViewerType(SceneViewerOption.IMAGE);
    }, [sceneGeneratedImageUrl]);

    const downloadImage = async (): Promise<void> => {
        if (!sceneGeneratedImageUrl) {
            return;
        }

        ImageHelpers.downloadPngByUrl(sceneGeneratedImageUrl);
    };

    const deleteImage = async (): Promise<void> => {
        if (!sceneGeneratedImageUrl) {
            return;
        }

        setSceneGeneratedImageUrl('');
        setSceneGeneratedLayerId(null);
    };

    const onConfirm = (): void => {
        resetToolInfo();
        setSceneGeneratedImageUrl('');
        setSceneGeneratedLayerId(null);
        setConfirmationModalState(false);
    };

    useEffect(() => {
        if (!sceneRef.current) {
            return;
        }

        initScene(sceneRef.current);
    }, [loadedModel]);

    return (
        <>
            <Wrapper>
                <ImagePlaceholder transparent={sceneViewerType === SceneViewerOption.IMAGE ? 1 : 0}>
                    {loadedModel ? (
                        <>
                            {!isSceneReady && (
                                <Loader>
                                    <span className="loader"></span>
                                </Loader>
                            )}
                            <Container transparent={sceneViewerType === SceneViewerOption.IMAGE ? 1 : 0}>
                                {sceneGeneratedImageUrl && sceneViewerType === SceneViewerOption.IMAGE && (
                                    <>
                                        <BackgroundImageBox onClick={handleClick}>
                                            <BackgroundImageContainer>
                                                <BackgroundImage
                                                    crossOrigin="anonymous"
                                                    isLoading={isImageLoading}
                                                    src={sceneGeneratedImageUrl}
                                                    onLoad={handleImageLoad}
                                                />
                                                {!isImageLoading && (
                                                    <ImagePreviewBox
                                                        type="snapshot"
                                                        onClick={handleClick}
                                                        imageUrl={lastSceneSnapshot}
                                                    ></ImagePreviewBox>
                                                )}
                                            </BackgroundImageContainer>
                                        </BackgroundImageBox>
                                    </>
                                )}
                                {isSceneReady && sceneViewerType === SceneViewerOption.SCENE && (
                                    <CurateSceneControlPanel
                                        uploadedFileName={uploadedFileName}
                                        onReset={recenterCamera}
                                        onClose={() => setConfirmationModalState(true)}
                                    />
                                )}
                                {sceneGeneratedLayerId && !sceneGeneratedImageUrl && (
                                    <ImageLoader
                                        variant="rectangular"
                                        animation="wave"
                                    />
                                )}
                                <Scene
                                    active={sceneViewerType === SceneViewerOption.SCENE ? 1 : 0}
                                    ref={sceneRef}
                                ></Scene>
                                {sceneGeneratedImageUrl && sceneViewerType === SceneViewerOption.SCENE && (
                                    <ImagePreviewBox
                                        type="image"
                                        onDelete={deleteImage}
                                        onDownload={downloadImage}
                                        onClick={handleClick}
                                        imageUrl={sceneGeneratedImageUrl}
                                    ></ImagePreviewBox>
                                )}
                            </Container>
                        </>
                    ) : (
                        <UploaderWrapper>
                            <DragAndDropFileUploader
                                addModel={addModelToScene}
                                currentFileName={'uploadedFileName'}
                                onError={setErrorType}
                                isLoading={isLoading}
                                maxFileSize={MAX_FILE_SIZE_3D_VIEWER}
                            />
                        </UploaderWrapper>
                    )}
                </ImagePlaceholder>
            </Wrapper>
            <SceneModeFooter
                isDisabled={
                    !loadedModel || sceneViewerType === SceneViewerOption.IMAGE || !!(sceneGeneratedLayerId && !sceneGeneratedImageUrl)
                }
            />
            <ConfirmationModal
                title={'Confirm the action'}
                message={'All model setting will be lost. Do you want to proceed?'}
                active={confirmationModalState}
                handleClose={() => setConfirmationModalState(false)}
                handleConfirm={onConfirm}
            />
        </>
    );
};

export default CurateScene;

const Wrapper = styled(Box)(() => ({
    flex: 3,
    height: 'calc(100% - 225px)',
    marginTop: '19px',
    marginBottom: '30px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const ImagePlaceholder = styled(Box)<{ transparent: number }>(({ theme, transparent }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',

    height: '100%',
    width: '80%',
    overflow: 'hidden',
    objectPosition: 'center',
    objectFit: 'contain',

    backgroundColor: transparent ? 'transparent' : theme.palette.primary.light,
    borderRadius: '15px',
}));

const Container = styled(Box)<{ transparent: number }>(({ theme, transparent }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',

    height: '100%',
    width: '100%',
    overflow: 'hidden',
    objectPosition: 'center',

    objectFit: 'contain',
    backgroundColor: transparent ? 'transparent' : theme.palette.primary.light,
}));

const Loader = styled(Box)(() => ({
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const Scene = styled(Box)<{ active: number }>(({ active }) => ({
    width: '100%',
    height: '100%',
    display: active ? 'flex' : 'none',
}));

const UploaderWrapper = styled(Box)(() => ({
    position: 'relative',
    width: '520px',
    height: '265px',
}));

const ImageLoader = styled(Skeleton)(() => ({
    position: 'absolute',
    height: '100%',
    width: '100%',

    background: 'rgba(0, 0, 0, 0.50)',
    borderRadius: '15px',
}));

const BackgroundImageBox = styled(Box)(() => ({
    position: 'absolute',
    height: '100%',
    cursor: 'pointer',
}));

const BackgroundImageContainer = styled(Box)(() => ({
    display: 'flex',
    objectPosition: 'center',
    flexDirection: 'column',
    position: 'relative',

    height: '100%',
    width: '100%',
    objectFit: 'contain',
}));

const BackgroundImage = styled('img')<{ isLoading: boolean }>(({ isLoading }) => ({
    filter: isLoading ? 'blur(10px)' : 'none',
    transition: 'all 0.4s ease-in-out',
    height: '100%',
    width: '100%',
    position: 'relative',

    display: 'flex',
    objectPosition: 'center',
    objectFit: 'contain',
}));
