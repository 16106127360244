import React, { useContext, useEffect, useState } from 'react';
import { ModelSectionProps } from './ModelSection.types';
import { Box, IconButton, TextField, Typography, styled } from '@mui/material';
import { getDate } from '@root/utils/getDate';
import ModelsContext from '@root/context/ModelsContext/ModelsContext';
import { ModelDto } from '@root/types/dto';
import { ModelStatus, ModelStatusColor, NavigationPaths, RequestErrorCode } from '@root/utils/constants/enums';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from '@root/context/NotificationsContext/useNotifications';
import { CommonConstants, ErrorMessages } from '@root/utils/constants';
import { DeleteImageIcon } from '@root/assets/icons/DeleteImageIcon';
import { useDrag } from '@root/context/DragContext/useDrag';
import DefaultModelPreview from '@root/assets/images/DefaultModelPreview.png';
import { ShareIcon } from '@root/assets/icons/ShareIcon';
import ActiveModelContext from '@root/context/ActiveModelContext/ActiveModelContext';
import { EditIcon } from '@root/assets/icons/EditIcon';
import { SaveIcon } from '@root/assets/icons/SaveIcon';
import ContentLoader from '@root/components/ContentLoader';
import { ErrorTooltip } from '@root/components/ErrorTooltip';

const CHECK_MODEL_STATUS_INTERVAL_TIME = 10000;

const ModelSection = ({ model }: ModelSectionProps): React.JSX.Element => {
    const navigate = useNavigate();
    const { createNotification, notificationMessages } = useNotifications();
    const { setDraggedObject, setDraggedImage } = useDrag();

    const {
        models,
        checkModelStatus,
        deleteModelById,
        unsavedModelId,
        setUnsavedModelId,
        unsavedModelName,
        setUnsavedModelName,
        selectedModelName,
        setSelectedModelName,
    } = useContext(ModelsContext);

    const { exportingModelIds, setExportModalState, updateModel, validateModelName } = useContext(ActiveModelContext);
    const { getModel, refreshModels } = useContext(ModelsContext);

    const [imageUrl, setImageUrl] = useState<string>('');
    const [parentModel, setParentModel] = useState<string>('');
    const [status, setStatus] = useState<number>(ModelStatus.Draft);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const handleNavigationToModelTrainPage = (): void => {
        navigate(`/${NavigationPaths.Models}/${model.Id}/`);
    };

    const deleteModel = (): void => {
        if (!model.CreatedById) {
            createNotification(notificationMessages.model.deleting.title, notificationMessages.model.deleting.info as string);
        } else {
            deleteModelById(model.Id);
        }
    };

    const navigationToShareModel = () => {
        handleNavigationToModelTrainPage();
        setExportModalState(true);
    };

    const startEditMode = (): void => {
        setUnsavedModelId(model.Id);
        setUnsavedModelName(model.Name);
        setSelectedModelName(model.Name);
    };

    const saveChanges = async (): Promise<void> => {
        if (unsavedModelName === selectedModelName) {
            stopEditMode();
            return;
        }

        const validationErrorMessage = validateModelName(unsavedModelName);
        if (validationErrorMessage) {
            setErrorMessage(validationErrorMessage);
            return;
        }

        const modelForUpdate = await getModel(unsavedModelId);
        const trainingSettings = modelForUpdate.model.Configurations !== 'default' && JSON.parse(modelForUpdate.model.Configurations);

        const modelInfo = {
            modelId: unsavedModelId,
            modelName: unsavedModelName,
            parentModelId: modelForUpdate.model.ParentId,
            conceptInstanceToken: trainingSettings.token,
            previewId: trainingSettings?.preview_id || null,
        };

        const result = await updateModel(modelInfo);
        if (result?.errorCode) {
            setErrorMessage(
                result?.errorCode === RequestErrorCode.MODEL_ALREADY_EXISTS
                    ? ErrorMessages.MODEL_ERROR_MESSAGE.ALREADY_EXISTS
                    : ErrorMessages.MODEL_ERROR_MESSAGE.UPDATE_ERROR
            );
            return;
        } else {
            refreshModels();
        }

        stopEditMode();
    };

    const stopEditMode = (): void => {
        setUnsavedModelId(null);
        setUnsavedModelName('');
        setSelectedModelName('');
    };

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (errorMessage) {
            setErrorMessage('');
        }
        setUnsavedModelName(event.target.value);
    };

    const blurHandler = (): void => {
        const validationErrorMessage = validateModelName(unsavedModelName);

        if (validationErrorMessage) {
            setErrorMessage(validationErrorMessage);
        }
    };

    const getModelPreviewFile = async (): Promise<File | null> => {
        if (imageUrl) {
            const result = await fetch(imageUrl);
            const blob = await result.blob();
            const file = new File([blob], 'preview.png', {
                type: 'image/png',
            });
            return file;
        } else {
            return null;
        }
    };

    const onDragStart = async (): Promise<void> => {
        const file = await getModelPreviewFile();
        setDraggedObject(model);
        setDraggedImage(file);
    };

    const onDragEnd = (): void => {
        setDraggedObject(null);
        setDraggedImage(null);
    };

    useEffect(() => {
        if (model.previewUrl) {
            setImageUrl(model.previewUrl);
        } else {
            setImageUrl(DefaultModelPreview);
        }

        const baseModel = models.find((el: ModelDto) => el.Id === model.ParentId);
        model.ParentId ? setParentModel(baseModel.Name) : setParentModel(CommonConstants.INFO_NOT_FOUND_LABEL);
        setStatus(model.StatusId);
    }, [model]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!model) {
                return;
            }
            checkModelStatus(model.Id).then((res: ModelStatus) => {
                if (status !== res) {
                    if (res === ModelStatus.Trained) {
                        createNotification(
                            notificationMessages.model.trained.title,
                            notificationMessages.model.trained.success as string,
                            'browser'
                        );
                    }
                    setStatus(res);
                }
            });
        }, CHECK_MODEL_STATUS_INTERVAL_TIME);

        if (
            status === ModelStatus.Trained ||
            status === ModelStatus.Merged ||
            status === ModelStatus.Draft ||
            status === ModelStatus.Error
        ) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [status]);

    return (
        <Wrapper>
            <ImageWrapper
                draggable
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onClick={handleNavigationToModelTrainPage}
            >
                {imageUrl && (
                    <img
                        crossOrigin="anonymous"
                        loading="lazy"
                        src={imageUrl}
                    />
                )}
            </ImageWrapper>

            <InfoWrapper>
                <ImageInfo>
                    {unsavedModelId === model.Id ? (
                        <>
                            <ErrorTooltip
                                title={errorMessage}
                                placement="right"
                                open={!!errorMessage}
                                arrow
                            >
                                <CustomInput
                                    id={model.Id.toString()}
                                    multiline={false}
                                    variant="outlined"
                                    onBlur={blurHandler}
                                    onChange={onChangeHandler}
                                    value={unsavedModelName}
                                />
                            </ErrorTooltip>
                        </>
                    ) : (
                        <NameLabel>{model.Name.replaceAll('.safetensors', '')}</NameLabel>
                    )}
                    <SecondaryLabel info={true}>{getDate(model.DateCreated)}</SecondaryLabel>

                    <InfoBlock action={false}>
                        <SecondaryLabel info={false}> {'Base model'} </SecondaryLabel>
                        <PrimaryLabel>{parentModel.replaceAll('.safetensors', '')}</PrimaryLabel>
                    </InfoBlock>

                    <InfoBlock action={false}>
                        <SecondaryLabel info={false}> {'Status'} </SecondaryLabel>
                        <StatusInfo>
                            <PrimaryLabel>{ModelStatus[status]}</PrimaryLabel>
                            <StatusLabel status={status}></StatusLabel>
                        </StatusInfo>
                    </InfoBlock>
                </ImageInfo>

                <InfoBlock
                    action={true}
                    row={true}
                >
                    {unsavedModelId === model.Id ? (
                        <ActionButton
                            onClick={saveChanges}
                            visible
                            disableRipple
                        >
                            <SaveIcon />
                        </ActionButton>
                    ) : (
                        <>
                            {model.CreatedById &&
                                (model.StatusId === ModelStatus.Draft ||
                                    model.StatusId === ModelStatus.Trained ||
                                    model.StatusId === ModelStatus.Merged) && (
                                    <ActionButton
                                        onClick={startEditMode}
                                        disableRipple
                                    >
                                        <EditIcon />
                                    </ActionButton>
                                )}
                        </>
                    )}
                    {(model.StatusId === ModelStatus.Trained || model.StatusId === ModelStatus.Merged) && (
                        <ActionButton
                            onClick={navigationToShareModel}
                            disabled={!!exportingModelIds.find((el: number) => el === model.Id)}
                            disableRipple
                        >
                            <ShareIcon />
                        </ActionButton>
                    )}
                    {model.CreatedById && (
                        <ActionButton
                            onClick={deleteModel}
                            disableRipple
                        >
                            <DeleteImageIcon />
                        </ActionButton>
                    )}
                </InfoBlock>
            </InfoWrapper>
        </Wrapper>
    );
};

export default ModelSection;

export const ModelSectionPlaceholder = ({ statusLabel }: { statusLabel?: string }): React.JSX.Element => {
    return (
        <Wrapper>
            <ImageWrapper>
                <img
                    crossOrigin="anonymous"
                    loading="lazy"
                    src={DefaultModelPreview}
                />
            </ImageWrapper>
            <InfoWrapper>
                <ImageInfo>
                    <NameLabel>{CommonConstants.INFO_NOT_FOUND_LABEL}</NameLabel>

                    <SecondaryLabel info={true}>{CommonConstants.INFO_NOT_FOUND_LABEL}</SecondaryLabel>

                    <InfoBlock action={false}>
                        <SecondaryLabel info={false}> {'Base model'} </SecondaryLabel>
                        <PrimaryLabel>{CommonConstants.INFO_NOT_FOUND_LABEL}</PrimaryLabel>
                    </InfoBlock>

                    <InfoBlock action={false}>
                        <SecondaryLabel info={false}> {'Status'} </SecondaryLabel>
                        <StatusInfo>
                            <PrimaryLabel>{statusLabel || CommonConstants.INFO_NOT_FOUND_LABEL}</PrimaryLabel>
                            <ContentLoader state={true} />
                        </StatusInfo>
                    </InfoBlock>
                </ImageInfo>
            </InfoWrapper>
        </Wrapper>
    );
};

export const Wrapper = styled(Box)(() => ({
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',

    gap: '13px',
    alignItems: 'start',
    objectFit: 'contain',

    maxWidth: '100%',
    maxHeight: '100%',
    width: 'max-content',

    '&:hover': {
        button: {
            opacity: '1 !important',
        },
    },
}));

const InfoBlock = styled(Box)<{ action: boolean; row?: boolean }>(({ action, row }) => ({
    display: 'flex',
    flexDirection: row ? 'row' : 'column',
    gap: '10px',
    marginTop: action ? '0px' : '24px',

    '@media (max-width:1600px)': {
        marginTop: action ? '0px' : '10px',
    },
}));

const ActionButton = styled(IconButton)<{ visible?: boolean }>(({ theme, visible }) => ({
    width: '24px',
    height: '24px',
    color: visible ? theme.palette.primary.main : theme.palette.primary.contrastText,
    opacity: visible ? 1 : '0',

    '& svg': {
        width: '24px',
        height: '24px',
    },

    '&:hover': {
        background: 'transparent',
    },
}));

const InfoWrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',

    maxWidth: '200px',
    height: '100%',

    '@media (max-width:1600px)': {
        maxWidth: '150px',
    },
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
    position: 'relative',
    height: '200px',
    width: '200px',

    backgroundColor: theme.palette.primary.light,
    aspectRatio: '1/1',
    borderRadius: '15px',
    overflow: 'hidden',

    '&:hover': {
        cursor: 'pointer',
    },

    '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },

    '@media (max-width:1600px)': {
        width: '150px',
        height: '150px',
    },
}));

const ImageInfo = styled(Box)(({ theme }) => ({
    display: 'flex',
    zIndex: '1',
    backgroundColor: theme.palette.neutrals.main,
    flexDirection: 'column',
    maxWidth: '200px',
    minHeight: '50px',
    height: '100%',

    '@media (max-width:1600px)': {
        maxWidth: '150px',
        height: '150px',
    },
}));

const NameLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto500',
    fontSize: '16px',
    lineHeight: '22px',

    marginBottom: '10px',
    color: theme.palette.neutrals.contrastText,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
}));

const SecondaryLabel = styled(Typography)<{ info: boolean }>(({ theme, info }) => ({
    height: '9px',

    fontFamily: 'Roboto400',
    fontSize: '12px',
    lineHeight: '14px',

    color: info ? theme.palette.neutrals.contrastText : theme.palette.neutrals.inactive1,
}));

const PrimaryLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto400',
    fontSize: '14px',
    lineHeight: '16px',
    color: theme.palette.neutrals.contrastText,

    '@media (max-width:1600px)': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
}));

const StatusInfo = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
}));

const StatusLabel = styled(Box)<{ status: number }>(({ status }) => ({
    width: '6px',
    height: '6px',
    borderRadius: '100%',
    marginBottom: '2px',

    background: ModelStatusColor[ModelStatus[status] as keyof typeof ModelStatusColor],
}));

const CustomInput = styled(TextField)(({ theme }) => ({
    width: '100%',
    marginBottom: '10px',

    '& input': {
        height: '10px',
        padding: '6px 10px',
        fontSize: '16px',
        color: theme.palette.primary.contrastText,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
}));
