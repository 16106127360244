import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import {
    Scene,
    BackSide,
    Group,
    Mesh,
    MeshStandardMaterial,
    Object3D,
    PCFSoftShadowMap,
    PerspectiveCamera,
    SphereGeometry,
    Vector3,
    WebGLRenderer,
    Box3,
    ShaderMaterial,
    SpotLight,
    CircleGeometry,
    TextureLoader,
    SRGBColorSpace,
    Light,
} from 'three';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { degToRad } from 'three/src/math/MathUtils';

import { SceneModeCurateContextItems } from '@root/types/contextTypes';
import { SceneModeOption, SceneViewerOption, SupportedModelTypes, SupportedModelTypesInfo } from '@root/utils/constants/enums';
import { ImageHelpers } from '@root/utils/helpers';
import {
    CAMERA_INITIAL_POSITION,
    CAMERA_MAX_DISTANCE,
    CAMERA_MAX_POLAR_ANGLE,
    CAMERA_MIN_POLAR_ANGLE,
    DRACO_DECODER_PATH,
    ENV_BORDERS,
    MATERIAL_METALNESS,
    MATERIAL_ROUGHNESS,
    MODEL_COLOR,
    INITIAL_SCENE_SIZE,
    SCENE_COLOR,
    LENS_INITIAL_VALUE,
} from '@root/utils/constants/SceneModeConstants';

type ModelTypes = 'obj' | 'stl' | 'fbx' | 'glb' | 'gltf';

const SceneModeCurateContext = createContext<SceneModeCurateContextItems | null>(null);

export const SceneModeCurateProvider = ({ children }: PropsWithChildren<unknown>): React.JSX.Element => {
    const [isLoading, setLoading] = useState(false);
    const [isSceneReady, setSceneReady] = useState(false);
    const [loadedModel, setLoadedModel] = useState<Object3D | null>(null);
    const [scene, setScene] = useState<Scene | null>(null);
    const [camera, setCamera] = useState<PerspectiveCamera | null>(null);
    const [renderer, setRenderer] = useState<WebGLRenderer | null>(null);
    const [controls, setControls] = useState<OrbitControls | null>(null);
    const [parentElement, setParentElement] = useState<HTMLDivElement | null>(null);
    const [uploadedFileName, setUploadedFileName] = useState<string>('');
    const [sceneGeneratedImageUrl, setSceneGeneratedImageUrl] = useState<string>('');
    const [sceneGeneratedLayerId, setSceneGeneratedLayerId] = useState<number | null>(null);
    const [lastSceneSnapshot, setLastSceneSnapshot] = useState<string>('');
    const [sceneViewerType, setSceneViewerType] = useState<SceneViewerOption>(SceneViewerOption.SCENE);

    const [lensValue, setLensValue] = useState<number>(LENS_INITIAL_VALUE);
    const [rollValue, setRollValue] = useState<number>(0);

    const [activeMode, setActiveMode] = useState<SceneModeOption>(SceneModeOption.STYLE_DRIVE);

    const loadModel = async (url: string, format: string): Promise<Mesh | Group> => {
        const loader = getLoader(format);

        return new Promise((resolve, reject) => {
            loader.load(
                url,
                (object) => {
                    if (format === 'glb' || format === 'gltf') {
                        const gltfObject = object as any;
                        resolve(gltfObject.scene as Group);
                    } else {
                        resolve(object instanceof Object3D ? object : new Group().add(object as any));
                    }
                },
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                () => {},
                (error) => reject(error)
            );
        });
    };

    const getLoader = (objType: string) => {
        switch (objType) {
            case SupportedModelTypesInfo[SupportedModelTypes.OBJ].name:
                return new OBJLoader();
            case SupportedModelTypesInfo[SupportedModelTypes.FBX].name:
                return new FBXLoader();
            case SupportedModelTypesInfo[SupportedModelTypes.GLTF].name:
            case SupportedModelTypesInfo[SupportedModelTypes.GLB].name: {
                const loader = new GLTFLoader();
                const dracoLoader = new DRACOLoader();
                dracoLoader.setDecoderPath(DRACO_DECODER_PATH);
                loader.setDRACOLoader(dracoLoader);
                return loader;
            }
            case SupportedModelTypesInfo[SupportedModelTypes.STL].name:
                return new STLLoader();

            default:
                throw new Error('Unsupported format');
        }
    };

    const addModelToScene = async (file: File): Promise<void> => {
        setLoading(true);
        const format = file.name.split('.').pop()?.toLowerCase() as ModelTypes;
        setUploadedFileName(file.name);

        if (['obj', 'stl', 'fbx', 'glb', 'gltf'].includes(format)) {
            const url = URL.createObjectURL(file);

            try {
                const model = await loadModel(url, format);
                setLoadedModel(model);
                URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error loading model:', error);
            } finally {
                setLoading(false);
            }
        } else {
            console.error('Unsupported file format');
            setLoading(false);
        }
    };

    const initScene = (domElement: HTMLDivElement) => {
        setParentElement(domElement);

        if (renderer) {
            domElement.appendChild(renderer.domElement);
            return;
        }

        const newScene = new Scene();
        const newCamera = new PerspectiveCamera(75, domElement.offsetWidth / domElement.offsetHeight, 0.1, 1000);
        const newRenderer = new WebGLRenderer({ antialias: true });

        newRenderer.setSize(domElement.offsetWidth, domElement.offsetHeight);
        newRenderer.shadowMap.enabled = true;
        newRenderer.shadowMap.type = PCFSoftShadowMap;

        newRenderer.outputColorSpace = SRGBColorSpace;

        newCamera.position.copy(CAMERA_INITIAL_POSITION);

        domElement.appendChild(newRenderer.domElement);

        const envObject = addEnv();
        newScene.add(envObject);

        const newControls = new OrbitControls(newCamera, newRenderer.domElement);
        configureControls(newControls);

        setScene(newScene);
        setCamera(newCamera);
        setRenderer(newRenderer);
        setControls(newControls);

        setLens(LENS_INITIAL_VALUE, newCamera);
        setRoll(0, newCamera);
    };

    const configureControls = (controls: OrbitControls) => {
        controls.enableDamping = true;
        controls.dampingFactor = 0.05;
        controls.maxDistance = CAMERA_MAX_DISTANCE;
        controls.minPolarAngle = CAMERA_MIN_POLAR_ANGLE;
        controls.maxPolarAngle = CAMERA_MAX_POLAR_ANGLE;
        controls.target.set(0, 0, 0);
    };

    const recenterCamera = () => {
        if (!controls || !camera || !loadedModel) {
            return;
        }
        const center = new Vector3().copy(loadedModel?.position);
        controls.target.copy(center);

        camera.position.copy(CAMERA_INITIAL_POSITION);
        setLens(LENS_INITIAL_VALUE);
        setRoll(0);

        controls.update();
    };

    const updateControlsPosition = (min: Vector3, max: Vector3) => {
        if (!controls || !camera) {
            return;
        }

        controls.target.clamp(min, max);

        const cameraPosition = camera.position.clone();
        const yPosition = cameraPosition.y;
        cameraPosition.clamp(min, max);
        cameraPosition.setY(yPosition);

        camera.position.copy(cameraPosition);

        controls.update();
    };

    const addLight = () => {
        if (!scene || !camera) {
            return;
        }

        const spotlight = new SpotLight(0xffffff, 0.1, 0, Math.PI / 4, 1, 0);
        const spotlight1 = new SpotLight(0xffffff, 0.5, 0, Math.PI / 12, 1, 2);
        const spotlight2 = new SpotLight(0xffffff, 0.3, 0, Math.PI / 8, 1, 2);

        spotlight.position.set(0, INITIAL_SCENE_SIZE, 0);
        spotlight1.position.set(0, INITIAL_SCENE_SIZE / 5, 0);
        spotlight2.position.set(0, INITIAL_SCENE_SIZE / 10, 0);

        spotlight2.castShadow = true;

        scene.add(spotlight, spotlight1, spotlight2);

        spotlight2.shadow.mapSize.width = 2048;
        spotlight2.shadow.mapSize.height = 2048;
    };

    const addEnv = (): Object3D => {
        const groundGeometry = new CircleGeometry(INITIAL_SCENE_SIZE, 64);

        const groundMaterial = new MeshStandardMaterial({
            color: 0xffffff,
            emissive: SCENE_COLOR,

            transparent: true,
        });

        const ground = new Mesh(groundGeometry, groundMaterial);
        ground.rotation.x = -Math.PI / 2;
        ground.receiveShadow = true;
        ground.renderOrder = 0;

        const backgroundGeometry = new SphereGeometry(INITIAL_SCENE_SIZE, 32, 16, 0, Math.PI * 2, 0, Math.PI / 2);

        const backgroundMaterial = new ShaderMaterial({
            vertexShader: `
              varying vec2 vUv;
              void main() {
                vUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
              }
            `,
            fragmentShader: `
              varying vec2 vUv;
              uniform vec3 topColor;
              uniform vec3 bottomColor;
              void main() {
                float gradient = smoothstep(0.0, 0.5, vUv.y);
                vec3 color = mix(vec3(0.161,0.149,0.18), vec3(1.0,1.0,1.0), gradient);
                gl_FragColor = vec4(color, 1.0);
              }
            `,
            side: BackSide,
        });

        const background = new Mesh(backgroundGeometry, backgroundMaterial);

        const gridPath = require('../../assets/images/grid.png');
        const textureLoader = new TextureLoader();
        const gridTexture = textureLoader.load(gridPath);

        const gridGeometry = new CircleGeometry(2, 64);

        const gridMaterial = new MeshStandardMaterial({
            color: 'white',
            emissive: 0x29262e,
            map: gridTexture,
            transparent: true,
        });

        const grid = new Mesh(gridGeometry, gridMaterial);
        grid.rotation.x = -Math.PI / 2;
        grid.receiveShadow = true;
        grid.position.setY(0.005);
        grid.renderOrder = 1;

        const group = new Group();
        group.name = 'environment';
        group.add(ground, background, grid);

        return group;
    };

    const setMaterial = (object: Mesh) => {
        const material = new MeshStandardMaterial({ color: MODEL_COLOR, metalness: MATERIAL_METALNESS, roughness: MATERIAL_ROUGHNESS });
        const objectAsMesh = object as Mesh;
        if (objectAsMesh.isMesh) {
            objectAsMesh.material = material;
            objectAsMesh.castShadow = true;
        }

        object.traverse((child) => {
            const childAsMesh = child as Mesh;
            if (childAsMesh.isMesh) {
                childAsMesh.material = material;
                childAsMesh.castShadow = true;
            }
        });
    };

    const focalLengthToFOV = (focalLength: number, sensorHeight = 24) => {
        return 2 * Math.atan(sensorHeight / (2 * focalLength)) * (180 / Math.PI);
    };

    const setLens = (lens: number, customCamera?: PerspectiveCamera) => {
        const sceneCamera = customCamera || camera;
        setLensValue(lens);

        if (!sceneCamera) {
            return;
        }
        sceneCamera.fov = focalLengthToFOV(lens);
        sceneCamera.updateProjectionMatrix();
    };

    const setRoll = (roll: number, customCamera?: PerspectiveCamera) => {
        const sceneCamera = customCamera || camera;
        setRollValue(roll);

        if (!sceneCamera) {
            return;
        }

        const rollAngle = degToRad(roll);
        sceneCamera.up.set(Math.sin(rollAngle), Math.cos(rollAngle), 0);
    };

    const addModel = () => {
        if (!scene || !loadedModel) {
            return;
        }

        const existingModel = scene.getObjectByName('model');
        if (existingModel) existingModel.removeFromParent();

        const group = loadedModel as Object3D;

        group.traverse((child) => {
            const childAsMesh = child as Mesh;
            if (childAsMesh.isMesh) {
                if (!childAsMesh.material) {
                    setMaterial(childAsMesh);
                }
            }
            const childAsLight = child as Light;

            if (childAsLight.isLight) {
                childAsLight.removeFromParent();
            }

            childAsMesh.castShadow = true;
        });

        setModelInCenter(group);

        //Scale model
        const boundingBox = new Box3().setFromObject(group);

        const size = new Vector3();
        boundingBox.getSize(size);

        const modelDiameter = Math.max(size.x, size.y, size.z);
        const scale = 2 / modelDiameter;
        group.scale.set(scale, scale, scale);

        group.position.set(0, 0, 0);

        setModelInCenter(group);

        group.name = 'model';
        group.castShadow = true;

        scene.add(group);
    };

    const setModelInCenter = (model: Object3D) => {
        const boundingBox = new Box3().setFromObject(model);

        const center = new Vector3();
        boundingBox.getCenter(center);

        model.position.x -= center.x;
        model.position.z -= center.z;
        model.position.y -= boundingBox.min.y;
    };

    const onWindowResize = () => {
        if (scene && camera && renderer && parentElement) {
            const width = parentElement.offsetWidth;
            const height = parentElement.offsetHeight;
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
            renderer.setSize(width, height);

            renderer.render(scene, camera);
        }
    };

    const animate = () => {
        if (!scene || !renderer || !camera) {
            return;
        }
        setSceneReady(true);

        requestAnimationFrame(animate);
        controls?.update();

        renderer.render(scene, camera);
    };

    const disposeScene = () => {
        renderer?.dispose();
        scene?.traverse((object) => {
            if (object instanceof Mesh) {
                object.geometry.dispose();
                if (object.material instanceof Array) {
                    object.material.forEach((mat) => mat.dispose());
                } else {
                    object.material.dispose();
                }
            }
        });
        scene?.clear();
    };

    const resetToolInfo = (): void => {
        disposeScene();

        setSceneReady(false);
        setUploadedFileName('');

        setParentElement(null);
        setScene(null);
        setCamera(null);
        setRenderer(null);
        setControls(null);
        setLoadedModel(null);

        setLens(LENS_INITIAL_VALUE);
        setRoll(0);
    };

    const saveSceneAsImage = (): File | null => {
        if (!renderer || !loadedModel || !scene || !camera) {
            return null;
        }
        renderer.render(scene, camera);

        const sceneContent = renderer.domElement.toDataURL('image/png');
        const sceneImage = ImageHelpers.getImageFileFromBase64String(sceneContent);

        setLastSceneSnapshot(sceneContent);

        return sceneImage;
    };

    useEffect(() => {
        window.addEventListener('resize', onWindowResize);

        return () => window.removeEventListener('resize', onWindowResize);
    }, [parentElement]);

    useEffect(() => {
        if (!controls || !scene) {
            return;
        }

        const envBoundingBox = new Box3().setFromCenterAndSize(new Vector3(), new Vector3().set(ENV_BORDERS, 1, ENV_BORDERS));
        const minEnvPosition = envBoundingBox.min;
        const maxEnvPosition = envBoundingBox.max;

        const onChange = () => {
            controls.removeEventListener('change', onChange);

            updateControlsPosition(minEnvPosition, maxEnvPosition);

            controls.addEventListener('change', onChange);
        };

        controls.addEventListener('change', onChange);

        return () => {
            controls.removeEventListener('change', onChange);
        };
    }, [controls]);

    useEffect(() => {
        if (scene) {
            addLight();
            addModel();
            animate();
        }
        return () => disposeScene();
    }, [renderer]);

    useEffect(() => {
        addModel();
    }, [loadedModel]);

    const value: SceneModeCurateContextItems = {
        isSceneReady,
        isLoading,
        loadedModel,
        addModelToScene,
        initScene,
        recenterCamera,
        setLens,
        setRoll,
        lensValue,
        rollValue,
        setActiveMode,
        activeMode,
        saveSceneAsImage,
        uploadedFileName,
        resetToolInfo,
        sceneGeneratedImageUrl,
        setSceneGeneratedImageUrl,
        lastSceneSnapshot,
        sceneGeneratedLayerId,
        setSceneGeneratedLayerId,
        sceneViewerType,
        setSceneViewerType,
    };

    return <SceneModeCurateContext.Provider value={value}>{children}</SceneModeCurateContext.Provider>;
};

export const useSceneModeCurate = (): SceneModeCurateContextItems => {
    const context = useContext(SceneModeCurateContext);
    if (context === null) {
        throw new Error('useSceneModeCurate cannot be used without its provider');
    }
    return context;
};
