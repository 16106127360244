import React, { useState } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { AddImageIngredientIcon } from '@root/assets/icons/AddImageIngredientIcon';
import { FileUploader } from 'react-drag-drop-files';
import ContentLoader from '@root/components/ContentLoader';
import { FileUploadIcon } from '@root/assets/icons/FileUploadIcon';
import { DragAndDropFileUploaderProps } from './DragAndDropFileUploader.types';
import { Model3dErrorCode } from '@root/utils/constants/enums';

const FILE_SIZE_ERROR_MESSAGE = 'The uploaded file is too large. File size limit is up to ';
const FILE_TYPE_ERROR_MESSAGE = 'The uploaded file has wrong type. Supported formats obj, stl, fbx, glb, gltf.';

const DragAndDropFileUploader = ({
    addModel,
    isLoading,
    currentFileName,
    onError,
    maxFileSize,
}: DragAndDropFileUploaderProps): React.JSX.Element => {
    const [uploadedFileName, setUploadedFileName] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    const handleUpload = async (file: File): Promise<void> => {
        if (file?.size > maxFileSize) {
            onError(Model3dErrorCode.FileSize);
            setErrorMessage(`${FILE_SIZE_ERROR_MESSAGE}${maxFileSize / 1024 / 1024} Mb.`);
            return;
        }

        if (file?.name) {
            setUploadedFileName(file.name);
        }
        await addModel(file);
    };

    const ImagePlaceholder = (): React.JSX.Element => {
        return (
            <ImagePlaceholderWrapper className={'action'}>
                <StyledAddImageIngredientIcon />
                <PlaceholderTypography className={'main'}>{'Choose the 3D model or drag it here. '}</PlaceholderTypography>
                <PlaceholderTypography variant="caption">
                    {`Supported obj, stl, fbx, glb, gltf without textures and animation. Up to ${maxFileSize / 1024 / 1024} Mb file size. `}
                </PlaceholderTypography>

                {errorMessage && <PlaceholderTypography className={'main'}>{errorMessage}</PlaceholderTypography>}
            </ImagePlaceholderWrapper>
        );
    };

    const LoadingPlaceholder = (): React.JSX.Element => {
        return (
            <LoadingPlaceholderWrapper>
                <StyledFileUploadIcon />
                <PlaceholderTypography className={'secondary'}>{'Uploading file, please wait'}</PlaceholderTypography>
                <LoadingInfo>
                    <ContentLoader state={isLoading} />
                    <InfoTypography>{uploadedFileName || currentFileName}</InfoTypography>
                </LoadingInfo>
            </LoadingPlaceholderWrapper>
        );
    };
    return (
        <>
            {isLoading ? (
                <LoadingPlaceholder />
            ) : (
                <FileUploader
                    onTypeError={() => setErrorMessage(FILE_TYPE_ERROR_MESSAGE)}
                    classes="drop_area"
                    disabled={false}
                    multiple={false}
                    handleChange={handleUpload}
                    types={['fbx', 'obj', 'gltf', 'glb', 'stl']}
                >
                    <ImageWrapper>
                        <ImagePlaceholder />
                    </ImageWrapper>
                </FileUploader>
            )}
        </>
    );
};

export default DragAndDropFileUploader;

const ImageWrapper = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '0',
    left: 0,
    width: '100%',
    height: '100%',

    backgroundColor: theme.palette.primary.light,

    borderRadius: '6px',

    cursor: 'inherit',
}));

const ImagePlaceholderWrapper = styled(Box)(({ theme }) => ({
    position: 'absolute',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    borderRadius: '6px',
    backgroundColor: theme.palette.primary.grey,
    border: `2px dashed ${theme.palette.primary.light1}`,
    margin: 'auto',
    top: '0',
    left: 0,
    bottom: 0,

    '&.action': {
        cursor: 'pointer',
    },
    width: '100%',
    height: '100%',
}));

const LoadingPlaceholderWrapper = styled(Box)(() => ({
    position: 'absolute',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    margin: 'auto',
    top: '0',
    left: 0,
    bottom: 0,

    width: '100%',
    height: '100%',
}));

const LoadingInfo = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    gap: '14px',
}));

const StyledAddImageIngredientIcon = styled(AddImageIngredientIcon)(({ theme }) => ({
    display: 'flex',
    height: '46px',
    width: '46px',
    color: theme.palette.primary.contrastText,
}));

const StyledFileUploadIcon = styled(FileUploadIcon)(({ theme }) => ({
    display: 'flex',
    height: '40px',
    width: '31px',
    color: theme.palette.primary.contrastText,
}));

const PlaceholderTypography = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto400',
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center',
    color: theme.palette.primary.inactive,

    '&.main': {
        color: theme.palette.primary.contrastText,
        padding: '14px 0px',
    },

    '&.secondary': {
        color: theme.palette.primary.contrastText,
        padding: '12px 0px',
    },
}));

const InfoTypography = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto400',
    fontSize: '14px',
    lineHeight: '16px',
    textAlign: 'center',
    maxWidth: '165px',

    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',

    color: theme.palette.primary.contrastText,
}));
