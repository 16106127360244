import React, { DragEvent, useEffect, useState } from 'react';
import { useDrag } from '@root/context/DragContext/useDrag';
import { AddImageIngredientIcon } from '@root/assets/icons/AddImageIngredientIcon';
import { Box, styled, Typography } from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import { DragAndDropPanelProps } from './DragAndDropPanel.types';
import AddImageSmall from '../../assets/images/AddImageSmall.png';
import { CommonConstants } from '@root/utils/constants';

const DragAndDropPanel = ({
    OnUpload,
    label,
    enabled,
    loadedImageUrl,
    weight,
    placeholderRatio,
    isPreviewMode = false,
}: DragAndDropPanelProps): JSX.Element => {
    const { draggedImage } = useDrag();
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [aspectRatio, setAspectRatio] = useState<string>(CommonConstants.DEFAULT_ASPECT_RATIO);

    const [isLabelWithItemName, setIsLabelWithItemName] = useState<boolean>(false);
    const [complexLabelParts, setComplexLabelParts] = useState<[string, string, string]>(['', '', '']);

    useEffect(() => {
        if (!label) {
            return;
        }

        const parts = label.split(CommonConstants.COMPLEX_LABEL_SEPARATOR);
        if (parts?.length === CommonConstants.DEFAULT_NUMBER_OF_COMPLEX_LABEL_PARTS) {
            setIsLabelWithItemName(true);
            setComplexLabelParts([parts[0], parts[1], parts[2]]);
        }
    }, []);

    useEffect(() => {
        if (!imageUrl && !loadedImageUrl) {
            setAspectRatio(CommonConstants.DEFAULT_ASPECT_RATIO);
            return;
        }

        const activeImage = new Image();
        activeImage.src = (loadedImageUrl ? loadedImageUrl : imageUrl) || '';

        if (activeImage.src) {
            activeImage.onload = () => {
                setAspectRatio(`${activeImage.width}/${activeImage.height}`);
            };
        }
    }, [imageUrl, loadedImageUrl]);

    const handleDragOver = (event: DragEvent<HTMLDivElement>): void => {
        event.stopPropagation();
        event.preventDefault();
    };

    const handleDrop = async (): Promise<void> => {
        if (enabled) {
            let file: File | Blob;
            if (draggedImage.src) {
                file = await fetch(draggedImage.src).then((res) => res.blob());
            } else {
                file = draggedImage;
            }
            handleUpload(file);
        }
    };

    const handleUpload = async (file: File | Blob): Promise<void> => {
        setImageUrl(file ? URL.createObjectURL(file) : '');
        OnUpload(file as File);
    };

    const ImagePlaceholder = (): JSX.Element => {
        return (
            <ImagePlaceholderWrapper defaultRatio={placeholderRatio}>
                <StyledAddImageIngredientIcon preview={isPreviewMode} />
                {!isPreviewMode &&
                    (isLabelWithItemName ? (
                        <PlaceholderTypography variant="caption">
                            {complexLabelParts[0]}
                            <span className="item-name">{complexLabelParts[1]}</span>
                            {complexLabelParts[2]}
                        </PlaceholderTypography>
                    ) : (
                        <PlaceholderTypography variant="caption">{label || 'choose the image or drag it here'}</PlaceholderTypography>
                    ))}
            </ImagePlaceholderWrapper>
        );
    };
    return (
        <Wrapper>
            <FileUploader
                disabled={!enabled}
                handleChange={handleUpload}
                types={['JPEG', 'PNG', 'JPG', 'WEBP']}
            >
                <ImageWrapper
                    className="drag-and-drop-image-wrapper"
                    preview={isPreviewMode}
                    active={+enabled}
                    ratio={aspectRatio}
                >
                    {!loadedImageUrl ? (
                        imageUrl ? (
                            <ImageSrc
                                crossOrigin="anonymous"
                                weight={weight || CommonConstants.DEFAULT_IMAGE_WEIGHT}
                                src={imageUrl}
                            />
                        ) : (
                            <ImagePlaceholder />
                        )
                    ) : (
                        <ImageSrc
                            crossOrigin="anonymous"
                            weight={weight || CommonConstants.DEFAULT_IMAGE_WEIGHT}
                            src={loadedImageUrl}
                        />
                    )}
                </ImageWrapper>
            </FileUploader>
            <Dropzone
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                enabled={!!draggedImage}
            />
        </Wrapper>
    );
};

const Wrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
}));

const ImageSrc = styled('img')<{ weight: number }>(({ weight }) => ({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    filter: `blur(${3 - (weight / 100) * 3}px)`,
}));

const ImageWrapper = styled(Box)<{ active: number; ratio: string; preview?: boolean }>(({ active, ratio, preview }) => ({
    maxWidth: preview ? '84px' : '300px',
    maxHeight: '170px',

    ...(!preview && {
        minWidth: '100px',
        minHeight: '100px',
    }),

    aspectRatio: ratio,
    overflow: 'hidden',
    cursor: active ? 'inherit' : 'no-drop',

    '& img': {
        borderRadius: preview ? '4px' : '12px',
    },
}));

const ImagePlaceholderWrapper = styled(Box)<{ defaultRatio?: string }>(({ theme, defaultRatio }) => ({
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    border: `1.5px dashed ${theme.palette.primary.light1}`,
    backgroundColor: theme.palette.primary.grey,
    backgroundImage: `url(${AddImageSmall})`,

    backgroundSize: defaultRatio ? 'cover' : 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',

    width: '300px',

    aspectRatio: defaultRatio ? defaultRatio : CommonConstants.DEFAULT_ASPECT_RATIO,
    height: defaultRatio ? 'fit-content' : '110px',
}));

const StyledAddImageIngredientIcon = styled(AddImageIngredientIcon)<{ preview?: boolean }>(({ theme, preview }) => ({
    display: 'flex',
    marginTop: '24px',
    margin: preview ? '0px' : '24px 14px 14px 14px',

    height: preview ? '30px' : '41px',
    width: preview ? '30px' : '41px',
    color: theme.palette.primary.grey,
}));

const Dropzone = styled(Box)<{ enabled: boolean }>(({ enabled }) => ({
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: enabled ? 'flex' : 'none',
}));

const PlaceholderTypography = styled(Typography)(({ theme }) => ({
    alignItems: 'center',
    paddingBottom: '10px',
    maxWidth: '270px',
    textAlign: 'center',
    color: theme.palette.primary.inactive,

    '& .item-name': {
        color: theme.palette.secondary.main,
    },
}));
export default DragAndDropPanel;
