import React, { PropsWithChildren, useState, createContext, useContext } from 'react';
import { LocalStorageKeys } from '@root/utils/constants';
import { UserRoles } from '@root/utils/constants/enums';
import { LocalStorageContextItems } from '@root/types/contextTypes';

const LocalStorageContext = createContext<LocalStorageContextItems | null>(null);

export const LocalStorageContextProvider = ({ children }: PropsWithChildren<unknown>): React.JSX.Element => {
    const [storageUsername, setStorageUsername] = useState<string | null>(localStorage.getItem(LocalStorageKeys.USERNAME_KEY));
    const [storageProjectId, setStorageProjectId] = useState<string | null>(localStorage.getItem(LocalStorageKeys.PROJECT_KEY));
    const [storageProjectName, setStorageProjectName] = useState<string | null>(localStorage.getItem(LocalStorageKeys.PROJECT_NAME_KEY));
    const [storageTheme, setStorageTheme] = useState<string | null>(localStorage.getItem(LocalStorageKeys.THEME_KEY));
    const [favoriteVisibility, setFavoriteVisibility] = useState<string | null>(localStorage.getItem(LocalStorageKeys.FAVORITE_KEY));
    const [lastActivePage, setLastActivePage] = useState<string | null>(localStorage.getItem(LocalStorageKeys.LAST_ACTIVE_PAGE_KEY));
    const [storageUserRole, setStorageUserRole] = useState<UserRoles | null>(
        localStorage.getItem(LocalStorageKeys.USER_ROLE_KEY) as unknown as UserRoles
    );

    const handleSetStorageUsername = (value: string): void => {
        localStorage.setItem(LocalStorageKeys.USERNAME_KEY, value);
        setStorageUsername(value);
    };
    const handleSetStorageProjectId = (value: string): void => {
        localStorage.setItem(LocalStorageKeys.PROJECT_KEY, value);
        setStorageProjectId(value);
    };

    const handleSetStorageProjectName = (value: string): void => {
        localStorage.setItem(LocalStorageKeys.PROJECT_NAME_KEY, value);
        setStorageProjectName(value);
    };

    const handleSetStorageAccessToken = (value: string): void => {
        localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN_KEY, value);
    };

    const handleSetStorageTheme = (value: string): void => {
        localStorage.setItem(LocalStorageKeys.THEME_KEY, value);
        setStorageTheme(value);
    };

    const handleSetFavoriteVisibility = (value: string): void => {
        localStorage.setItem(LocalStorageKeys.FAVORITE_KEY, value);
        setFavoriteVisibility(value);
    };

    const handleSetStorageUserRole = (value: string): void => {
        localStorage.setItem(LocalStorageKeys.USER_ROLE_KEY, value);
        setStorageUserRole(value as unknown as UserRoles);
    };

    const handleSetLastActivePage = (value: string): void => {
        localStorage.setItem(LocalStorageKeys.LAST_ACTIVE_PAGE_KEY, value);
        setLastActivePage(value);
    };

    const value = {
        storageUsername,
        setStorageUsername: handleSetStorageUsername,
        storageProjectId: storageProjectId ? +storageProjectId : null,
        setStorageProjectId: handleSetStorageProjectId,
        storageProjectName,
        setStorageProjectName: handleSetStorageProjectName,
        setStorageAccessToken: handleSetStorageAccessToken,
        storageTheme,
        setStorageTheme: handleSetStorageTheme,
        favoriteVisibility,
        setFavoriteVisibility: handleSetFavoriteVisibility,
        storageUserRole,
        setStorageUserRole: handleSetStorageUserRole,
        lastActivePage,
        setLastActivePage: handleSetLastActivePage,
    };

    return <LocalStorageContext.Provider value={value}>{children}</LocalStorageContext.Provider>;
};

export const useLocalStorage = (): LocalStorageContextItems => {
    const context = useContext(LocalStorageContext);

    if (context === null) {
        throw new Error('useLocalStorage cannot be used without its provider');
    }

    return context;
};
