import React from 'react';
import { Box, Typography, Skeleton, styled } from '@mui/material';
import CuratedCanvas from './CuratedCanvas';
import { useCurate } from '@root/hooks/curate/useCurate';
import { useMixImage } from '@root/context/MixImageContext/useMixImage';
import { useStyleDrive } from '@root/context/StyleDriveContext/useStyleDrive';
import { useSceneShift } from '@root/context/SceneShiftContext/useSceneShift';
import StyleDriveFooter from './StyleDriveFooter';
import { CurateTools } from '@root/utils/constants/enums';
import MixImageFooter from './MixImageFooter';
import SceneShiftFooter from './SceneShiftFooter';

const CurateImage = (): React.JSX.Element => {
    const { activeImageUrlMix } = useMixImage();
    const { activeImageUrlSD } = useStyleDrive();
    const { activeImageUrlSceneShift } = useSceneShift();
    const { currentlyInProgress, activeTool, isImagesLoading } = useCurate();

    const CurateImagePlaceholder = () => {
        return (
            <ImagePlaceholder>
                <PlaceholderTypography>{'Get started by uploading images with different style and concept'}</PlaceholderTypography>
                {currentlyInProgress > 0 && (
                    <ImageLoader
                        variant="rectangular"
                        animation="wave"
                    />
                )}
            </ImagePlaceholder>
        );
    };

    return (
        <>
            {isImagesLoading && !activeTool ? (
                <Wrapper>
                    <CurateImagePlaceholder />
                </Wrapper>
            ) : (
                <Wrapper
                    className={`${activeTool === CurateTools.MixImages ? 'mix-images' : ''}
                        ${activeTool === CurateTools.StyleDrive || activeTool === CurateTools.SceneShift ? 'tool-footer' : ''}`}
                >
                    {(activeTool === CurateTools.MixImages && !activeImageUrlMix) ||
                    (activeTool === CurateTools.StyleDrive && !activeImageUrlSD) ||
                    (activeTool === CurateTools.SceneShift && !activeImageUrlSceneShift) ? (
                        <CurateImagePlaceholder />
                    ) : (
                        <CuratedCanvas />
                    )}
                </Wrapper>
            )}
            {activeTool === CurateTools.MixImages && <MixImageFooter></MixImageFooter>}

            {activeTool === CurateTools.StyleDrive && <StyleDriveFooter></StyleDriveFooter>}

            {activeTool === CurateTools.SceneShift && <SceneShiftFooter></SceneShiftFooter>}
        </>
    );
};

export default CurateImage;

const Wrapper = styled(Box)(({ theme }) => ({
    position: 'relative',
    flex: 3,
    height: 'calc(100% - 81px)',
    marginTop: '19px',
    marginBottom: '30px',

    '&.mix-images': {
        marginBottom: '0px',
    },

    '&.tool-footer': {
        height: 'calc(100% - 285px)',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&:hover > button': {
        background: theme.palette.primary.light,
        opacity: 1,
    },
}));

const ImageLoader = styled(Skeleton)(() => ({
    position: 'absolute',
    zIndex: 10,
    background: 'rgba(0, 0, 0, 0.50)',
    borderRadius: '15px',
    height: '100%',
    width: '60%',
}));

const ImagePlaceholder = styled(Box)(({ theme }) => ({
    display: 'flex',
    height: '100%',
    width: '60%',
    objectPosition: 'center',
    objectFit: 'contain',
    backgroundColor: theme.palette.primary.light,
    borderRadius: '15px',
    justifyContent: 'center',
    alignItems: 'center',
}));

const PlaceholderTypography = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto400',
    fontSize: '20px',
    lineHeight: '22px',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.neutrals.inactive1,
}));
