import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, styled } from '@mui/material';
import { useCurate } from '@hooks/curate/useCurate';
import { useMixImage } from '@root/context/MixImageContext/useMixImage';
import { FormWrapper } from '@root/components/FormWrapper';
import { useStyleDrive } from '@root/context/StyleDriveContext/useStyleDrive';
import PromptSection from '@root/components/PromptSection';
import ExpandMenuButton from '@root/components/ExpandMenuButton';
import { CurateTools, HotKeysList, SceneModeOption, SceneViewerOption, SupportedHotKeys } from '@root/utils/constants/enums';
import useKeyPress from '@root/hooks/helpers/useKeyPress';
import { useLicenseValidation } from '@root/context/LicenseContext/useLicenseValidation';
import { useSceneShift } from '@root/context/SceneShiftContext/useSceneShift';
import { useSceneModeCurate } from '@root/context/SceneModeCurateContext/useSceneModeCurate';
import ToolWithModelsList from './CurateToolsFormContent/ToolWithModelsList';
import BrushToolContent from './CurateToolsFormContent/BrushToolContent';
import LassoToolContent from './CurateToolsFormContent/LassoToolContent';
import StyleDriveToolContent from './CurateToolsFormContent/StyleDriveToolContent';
import SceneModeForm from './CurateToolsFormContent/SceneModeForm';

const CuratePageForm = (): React.JSX.Element => {
    const {
        rerenderImage,
        generateButtonState,
        rerenderFromSketch,
        activeTool,
        generateSceneShift,
        prompt,
        onPromptInputChange,
        seed,
        formMenuIsActive,
        setFormMenuIsActive,
        generateStyleDrive,
        generateMixImage,
        generateWithScene,
        sceneGeneratedLayerId,
        sceneGeneratedImageUrl,
        setSceneViewerType,
    } = useCurate();

    const { checkLicenseStatus } = useLicenseValidation();
    const isSubmitStarted = useRef<boolean>(false);

    const generateHotKey = useKeyPress(
        SupportedHotKeys[HotKeysList.CTRL_G].key,
        SupportedHotKeys[HotKeysList.CTRL_G].withCtrl,
        SupportedHotKeys[HotKeysList.CTRL_G].blockDefaultEvent
    );

    const { readyToMix } = useMixImage();
    const { isReadyForGeneration, setFirstInfluenceSD, setFirstInfluenceImageSD, firstInfluenceImageSD, firstInfluenceSD } =
        useStyleDrive();

    const {
        isSceneShiftGenerationEnabled,
        sceneShiftPrompt,
        onSceneShiftPromptChange,
        setInfluenceImageSceneShift,
        setInfluenceSceneShift,
        influenceSceneShift,
    } = useSceneShift();

    const { activeMode, setActiveMode, loadedModel } = useSceneModeCurate();

    const [isVisible, setIsVisible] = useState<boolean>(true);

    const toolsWithForm = activeTool in CurateTools && activeTool !== CurateTools.Upscale && activeTool !== CurateTools.Eraser;

    const buttonAction = (): void => {
        setFormMenuIsActive(!formMenuIsActive);
    };

    const onSubmit = async (event?: React.FormEvent<HTMLDivElement>): Promise<void> => {
        if (event) {
            event.preventDefault();
        }

        if (isSubmitStarted.current) {
            return;
        }

        isSubmitStarted.current = true;

        const isValid = await checkLicenseStatus();

        if (!isValid) {
            return;
        }

        switch (activeTool) {
            case CurateTools.MixImages:
                generateMixImage();
                break;
            case CurateTools.Lasso:
                rerenderImage();
                break;
            case CurateTools.Brush:
                rerenderFromSketch();
                break;
            case CurateTools.SceneShift:
                generateSceneShift();
                break;
            case CurateTools.StyleDrive:
                generateStyleDrive();
                break;
            case CurateTools.SceneMode:
                setSceneViewerType(SceneViewerOption.SCENE);
                generateWithScene();
                break;
        }

        isSubmitStarted.current = false;
    };

    const isToolGenerationEnabled = (tool: CurateTools): boolean => {
        switch (tool) {
            case CurateTools.MixImages:
                return readyToMix && seed;
            case CurateTools.Lasso:
            case CurateTools.Brush:
                return generateButtonState && seed;
            case CurateTools.SceneShift:
                return isSceneShiftGenerationEnabled && !!sceneShiftPrompt;
            case CurateTools.StyleDrive:
                return isReadyForGeneration && seed;
            case CurateTools.SceneMode:
                return (
                    !!loadedModel &&
                    (activeMode === SceneModeOption.SCENE_SHIFT ? !!prompt : !!firstInfluenceImageSD) &&
                    !(sceneGeneratedLayerId && !sceneGeneratedImageUrl)
                );
            default:
                return false;
        }
    };

    useEffect(() => {
        if (!generateHotKey) {
            return;
        }

        if (isToolGenerationEnabled(activeTool)) {
            onSubmit();
        }
    }, [generateHotKey]);

    useEffect(() => {
        setIsVisible(toolsWithForm);
        setFormMenuIsActive(toolsWithForm);
    }, [activeTool]);

    return (
        <Wrapper enabled={+isVisible}>
            <FormWrapper
                large
                onSubmit={onSubmit}
                component="form"
                active={+formMenuIsActive}
            >
                <Content>
                    {activeTool === CurateTools.Brush && <BrushToolContent />}
                    {activeTool === CurateTools.Lasso && <LassoToolContent />}
                    {activeTool === CurateTools.MixImages && <ToolWithModelsList />}
                    {activeTool === CurateTools.SceneShift && (
                        <PromptSection
                            prompt={sceneShiftPrompt}
                            onPromptInputChange={onSceneShiftPromptChange}
                        ></PromptSection>
                    )}
                    {activeTool === CurateTools.StyleDrive && <StyleDriveToolContent />}
                    {activeTool === CurateTools.SceneMode && (
                        <SceneModeForm
                            prompt={activeMode === SceneModeOption.SCENE_SHIFT ? sceneShiftPrompt : prompt}
                            onPromptChange={activeMode === SceneModeOption.SCENE_SHIFT ? onSceneShiftPromptChange : onPromptInputChange}
                            setReferenceImage={
                                activeMode === SceneModeOption.SCENE_SHIFT ? setInfluenceImageSceneShift : setFirstInfluenceImageSD
                            }
                            referenceImageInfluence={activeMode === SceneModeOption.SCENE_SHIFT ? influenceSceneShift : firstInfluenceSD}
                            setReferenceImageInfluence={
                                activeMode === SceneModeOption.SCENE_SHIFT ? setInfluenceSceneShift : setFirstInfluenceSD
                            }
                            selectedGenerationTool={activeMode}
                            onSelectedGenerationToolChange={setActiveMode}
                        ></SceneModeForm>
                    )}
                </Content>
                <Footer>
                    <GenerateButton
                        disabled={!isToolGenerationEnabled(activeTool)}
                        type="submit"
                        variant="contained"
                    >
                        {activeTool === CurateTools.MixImages ? 'Mix Images' : 'Generate'}
                    </GenerateButton>
                </Footer>
            </FormWrapper>
            <ExpandMenuButton
                isActive={formMenuIsActive}
                buttonAction={buttonAction}
            />
        </Wrapper>
    );
};

export default CuratePageForm;

const Wrapper = styled(Box)<{ enabled: number }>(({ enabled }) => ({
    display: 'flex',
    flex: 1,
    maxWidth: '413px',
    opacity: !enabled ? 0 : 1,
}));

const Content = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingBottom: '10px',

    overflowY: 'auto',
    overflowX: 'hidden',
    gap: '32px',
}));

const GenerateButton = styled(Button)(() => ({
    width: '300px',
    transition: 'all 0.5s',
}));

const Footer = styled(Box)(() => ({
    display: 'flex',
    height: '94px',
    padding: '25px 0px',
}));
