export const CUSTOM_CAMERA_VIEW_TITLE_PREFIX = 'Custom ';
export const CUSTOM_CAMERA_VIEW_MODEL_PREFIX = 'my model ';
export const DEFAULT_MODEL_NAME = 'Default 1';
export const DEFAULT_MODEL_POSITION = { x: 0, y: 0, z: 0 };
export const DEFAULT_MODEL_ROTATION = { x: 0, y: 0, z: 0 };
export const DEFAULT_MODEL_ZOOM = 1;
export const DEFAULT_CAMERA_VIEW_TITLE_PREFIX = 'default';
export const MAX_FILE_SIZE = 1024 * 1024 * 8;
export const MAX_FILE_SIZE_3D_VIEWER = 1024 * 1024 * 300;

export const DEFAULT_MATERIAL_SETTING = {
    color: 0x878787,
    emissive: 0x000000,
    metalness: 0.5,
    roughness: 0.5,
    ior: 2,
    reflectivity: 0.6,
    iridescence: 0,
    iridescenceIOR: 1,
    sheen: 1,
    sheenRoughness: 1,
    sheenColor: 0x000000,
    clearcoat: 0.1,
    clearcoatRoughness: 0,
    specularIntensity: 1,
    specularColor: 0x87b4ea,
};
export const DEFAULT_LIGHT_COLOR = 0xffffff;
export const UPLOADED_MODEL_NAME = 'custom_model';
export const DEFAULT_CAMERA_VIEW_WEIGHT = 35;
