import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { UserRoles } from '@root/utils/constants/enums';
import { clearAuthStorage } from '@root/utils/auth/clearAuthStorage';
import keycloak from './keycloak-config';
import { AuthContextItems } from '@root/types/contextTypes';
import { accessTokenUpdate } from '@root/hooks/request/accessTokenUpdate';
import { useLocalStorage } from '@root/context/LocalStorageContext/useLocalStorage';

const AuthContext = createContext<AuthContextItems | null>(null);

export const AuthContextProvider = ({ children }: PropsWithChildren<unknown>): React.JSX.Element => {
    const { storageUsername, setStorageUsername, setStorageAccessToken, storageUserRole, setStorageUserRole } = useLocalStorage();

    const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
    const [username, setUsername] = useState<string | null>(storageUsername);
    const [userRole, setUserRole] = useState<UserRoles | null>(storageUserRole ? +storageUserRole : null);

    useEffect(() => {
        keycloak.init({ onLoad: 'login-required' }).then((authenticated: boolean) => {
            if (authenticated) {
                handleLogin();
            }
        });
    }, []);

    const handleLogin = async (): Promise<void> => {
        setStorageAccessToken(keycloak?.token || '');
        accessTokenUpdate();

        const userInfo = keycloak?.tokenParsed;

        if (!userInfo) {
            handleLogout();
            return;
        }

        setIsSignedIn(userInfo !== null);

        setStorageUserRole(userInfo.roleId || UserRoles.User);
        setUserRole(+userInfo.roleId || UserRoles.User);

        setUsername(userInfo.username);
        setStorageUsername(userInfo.username);
    };

    const handleLogout = (): void => {
        setIsSignedIn(false);
        clearAuthStorage();
        delete axios.defaults.headers.common['Authorization'];
        keycloak.logout();
    };

    return (
        <AuthContext.Provider
            value={{
                isSignedIn,
                username,
                userRole,
                handleLogin,
                handleLogout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextItems => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('Can not use useAuth out of AuthContext.');
    }
    return context;
};
