import React, { useEffect } from 'react';
import { styled, Modal, Stack } from '@mui/material';
import BorderRadiusFilter from '@root/assets/filters';
import { DeleteImageIcon } from '@root/assets/icons/DeleteImageIcon';
import { DownloadIcon } from '@root/assets/icons/DownloadIcon';
import useKeyPress from '@root/hooks/helpers/useKeyPress';
import { CommonConstants } from '@root/utils/constants';
import {
    MetadataImageBox,
    MetadataInfoWrapper,
    MetadataLeftPanel,
    MetadataRightPanel,
    MetadataSectionWrapper,
    MetadataToolButton,
    MetadataTools,
} from '@root/components/ImageMetadataPanel';
import CopyLinkButton from '@root/components/CopyValueButton';
import ImageMetadataRow from '@root/components/ImageMetadataRow';
import BackButton from '@root/components/BackButton';
import ForwardButton from '@root/components/ForwardButton';
import { PanoramaMetadataDialogProps } from './PanoramaMetadataDialog.types';

const PanoramaMetadataDialog = ({
    active,
    handleClose,
    selectedImageInfo,
    moveToNext,
    moveToPrevious,
    deleteById,
    downloadImage,
}: PanoramaMetadataDialogProps): React.JSX.Element => {
    const arrowUpPressed = useKeyPress(CommonConstants.ARROW_BUTTONS.UP);
    const arrowDownPressed = useKeyPress(CommonConstants.ARROW_BUTTONS.DOWN);
    const arrowLeftPressed = useKeyPress(CommonConstants.ARROW_BUTTONS.LEFT);
    const arrowRightPressed = useKeyPress(CommonConstants.ARROW_BUTTONS.RIGHT);

    useEffect(() => {
        if (active && (arrowDownPressed || arrowRightPressed)) {
            moveToNext();
        }
    }, [arrowDownPressed, arrowRightPressed]);

    useEffect(() => {
        if (active && (arrowUpPressed || arrowLeftPressed)) {
            moveToPrevious();
        }
    }, [arrowUpPressed, arrowLeftPressed]);

    const deleteByClick = (event: React.MouseEvent): void => {
        event.preventDefault();
        event.stopPropagation();

        if (selectedImageInfo?.id) {
            deleteById(selectedImageInfo.id);
        }
    };

    return selectedImageInfo?.id ? (
        <MetadataModal
            open={active}
            onClose={handleClose}
        >
            <MetadataSectionWrapper>
                <BorderRadiusFilter />
                <>
                    <BackButton moveToPrevious={moveToPrevious} />
                    <ForwardButton moveToNext={moveToNext} />
                </>
                <MetadataLeftPanel>
                    <MetadataImageBox>
                        <img
                            crossOrigin="anonymous"
                            src={selectedImageInfo?.url}
                        />
                    </MetadataImageBox>
                </MetadataLeftPanel>
                <MetadataRightPanel>
                    <MetadataInfoWrapper>
                        <MetadataTools>
                            <MetadataToolButton onClick={downloadImage}>
                                <DownloadIcon />
                            </MetadataToolButton>
                            <MetadataToolButton>
                                <CopyLinkButton value={selectedImageInfo?.url} />
                            </MetadataToolButton>
                            <MetadataToolButton onClick={deleteByClick}>
                                <DeleteImageIcon />
                            </MetadataToolButton>
                        </MetadataTools>
                        <ImageMetadataRow
                            title={'prompt'}
                            info={selectedImageInfo?.prompt || CommonConstants.INFO_NOT_FOUND_LABEL}
                        />
                        <ImageMetadataRow
                            title={'negative prompt'}
                            info={selectedImageInfo?.negative_prompt || CommonConstants.INFO_NOT_FOUND_LABEL}
                        />
                        <ImageMetadataRow
                            title={'seed'}
                            info={`${selectedImageInfo?.seed < 0 ? CommonConstants.DEFAULT_SEED_VALUE : selectedImageInfo?.seed || CommonConstants.INFO_NOT_FOUND_LABEL}`}
                        />
                    </MetadataInfoWrapper>
                </MetadataRightPanel>
            </MetadataSectionWrapper>
        </MetadataModal>
    ) : (
        <></>
    );
};

export default PanoramaMetadataDialog;

const MetadataModal = styled(Modal)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    outline: 'none !important',
    background: theme.palette.secondary.grey,
}));
