import React, { useEffect, useContext } from 'react';
import { styled, Modal, Stack } from '@mui/material';
import {
    MetadataImageBox,
    MetadataInfoWrapper,
    MetadataLeftPanel,
    MetadataRightPanel,
    MetadataSectionWrapper,
    MetadataToolButton,
    MetadataTools,
} from '@root/components/ImageMetadataPanel';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { ImageMetadataDialogProps } from './ImageMetadataDialog.types';
import BorderRadiusFilter from '@root/assets/filters';
import useKeyPress from '@root/hooks/helpers/useKeyPress';
import { CommonConstants } from '@root/utils/constants';
import ModelsContext from '@root/context/ModelsContext/ModelsContext';
import { DeleteImageIcon } from '@root/assets/icons/DeleteImageIcon';
import { DownloadIcon } from '@root/assets/icons/DownloadIcon';
import CopyLinkButton from '@root/components/CopyValueButton';
import ImageMetadataDetails from './ImageMetadataDetails';
import ImageMetadataRow from '@root/components/ImageMetadataRow';
import BackButton from '@root/components/BackButton';
import ForwardButton from '@root/components/ForwardButton';
import { CurateTools } from '@root/utils/constants/enums';

const ImageMetadataDialog = ({
    imageUrl,
    active,
    handleClose,
    selectedImageInfo,
    moveToNext,
    moveToPrevious,
    deleteById,
    downloadImage,
    sketchImageUrl,
}: ImageMetadataDialogProps): React.JSX.Element => {
    const { getModelNameByConfiguration } = useContext(ModelsContext);

    const arrowUpPressed = useKeyPress(CommonConstants.ARROW_BUTTONS.UP);
    const arrowDownPressed = useKeyPress(CommonConstants.ARROW_BUTTONS.DOWN);
    const arrowLeftPressed = useKeyPress(CommonConstants.ARROW_BUTTONS.LEFT);
    const arrowRightPressed = useKeyPress(CommonConstants.ARROW_BUTTONS.RIGHT);

    useEffect(() => {
        if (active && (arrowDownPressed || arrowRightPressed)) {
            moveToNext();
        }
    }, [arrowDownPressed, arrowRightPressed]);

    useEffect(() => {
        if (active && (arrowUpPressed || arrowLeftPressed)) {
            moveToPrevious();
        }
    }, [arrowUpPressed, arrowLeftPressed]);

    const deleteByClick = (event: React.MouseEvent): void => {
        event.preventDefault();
        event.stopPropagation();

        deleteById(selectedImageInfo.id);
    };

    return (
        selectedImageInfo && (
            <MetadataModal
                open={active}
                onClose={handleClose}
            >
                <MetadataSectionWrapper>
                    <BorderRadiusFilter />
                    <>
                        <BackButton moveToPrevious={moveToPrevious} />
                        <ForwardButton moveToNext={moveToNext} />
                    </>
                    <MetadataLeftPanel>
                        <MetadataImageBox>
                            <TransformWrapper
                                disablePadding={true}
                                limitToBounds={true}
                            >
                                <TransformComponent>
                                    {sketchImageUrl ? (
                                        <OverlayImage
                                            crossOrigin="anonymous"
                                            src={sketchImageUrl}
                                            backgroundImageUrl={imageUrl}
                                        ></OverlayImage>
                                    ) : (
                                        <img
                                            crossOrigin="anonymous"
                                            loading="lazy"
                                            src={imageUrl}
                                        />
                                    )}
                                </TransformComponent>
                            </TransformWrapper>
                        </MetadataImageBox>
                    </MetadataLeftPanel>
                    <MetadataRightPanel>
                        <MetadataInfoWrapper>
                            <MetadataTools>
                                <MetadataToolButton onClick={downloadImage}>
                                    <DownloadIcon />
                                </MetadataToolButton>
                                <MetadataToolButton>
                                    <CopyLinkButton value={imageUrl} />
                                </MetadataToolButton>
                                <MetadataToolButton onClick={deleteByClick}>
                                    <DeleteImageIcon />
                                </MetadataToolButton>
                            </MetadataTools>
                            <ImageMetadataRow
                                title={'prompt'}
                                info={selectedImageInfo.metadata?.prompt || CommonConstants.INFO_NOT_FOUND_LABEL}
                            />
                            <ImageMetadataRow
                                title={'negative prompt'}
                                info={selectedImageInfo.metadata?.negativePrompt || CommonConstants.INFO_NOT_FOUND_LABEL}
                            />
                            <ImageMetadataRow
                                title={'model'}
                                info={
                                    selectedImageInfo.metadata &&
                                    selectedImageInfo.toolId !== CurateTools.Eraser &&
                                    selectedImageInfo.toolId !== CurateTools.Upscale
                                        ? getModelNameByConfiguration(selectedImageInfo.metadata)
                                        : CommonConstants.INFO_NOT_FOUND_LABEL
                                }
                            />
                            <ImageMetadataRow
                                title={'seed'}
                                info={`${selectedImageInfo.metadata?.seed || CommonConstants.INFO_NOT_FOUND_LABEL}`}
                            />
                            <InfoRow>
                                <ImageMetadataDetails info={selectedImageInfo} />
                            </InfoRow>
                        </MetadataInfoWrapper>
                    </MetadataRightPanel>
                </MetadataSectionWrapper>
            </MetadataModal>
        )
    );
};

export default ImageMetadataDialog;

const MetadataModal = styled(Modal)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    outline: 'none !important',
    background: theme.palette.secondary.grey,
}));

const OverlayImage = styled('img')<{ backgroundImageUrl: string }>(({ backgroundImageUrl }) => ({
    objectFit: 'contain',
    background: `no-repeat url(${backgroundImageUrl})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
}));

const InfoRow = styled(Stack)(() => ({
    display: 'flex',
    flexDirection: 'row',
}));
