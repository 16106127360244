import React from 'react';
import { Box, styled, Stack, Typography } from '@mui/material';
import InfoBlock from '@root/components/InfoBlock';
import { prefixBaseUrl } from '@root/utils/url/url';
import { ImageMetadataDetailsProps } from './ImageMetadataDetails.types';
import ImageInfluenceInfo from '@root/components/ImageInfluenceInfo';
import { CurateToolsFullNames } from '@root/utils/constants/curateToolsNames';

const ImageMetadataDetails = ({ info }: ImageMetadataDetailsProps): React.JSX.Element => {
    return (
        <ToolInfoWrapper>
            <ToolLabel variant={'body16'}>{info.toolId ? CurateToolsFullNames[info.toolId] : ''}</ToolLabel>
            <ToolInfoContent>
                {info.metadata?.inputImageUrl && (
                    <ToolInfoItem>
                        <InfoBlock
                            fullWidth
                            isPrimary={false}
                            title={'input image'}
                            infoImage={info.metadata.inputImageUrl}
                        />
                        {info.metadata?.sketchWeight && (
                            <ImageInfluenceInfo
                                title={'influence'}
                                value={`${info.metadata.sketchWeight * 100}%`}
                            />
                        )}
                    </ToolInfoItem>
                )}
                {info.metadata?.inputMaskUrl && (
                    <ToolInfoItem>
                        <InfoBlock
                            fullWidth
                            isPrimary={false}
                            title={'input mask'}
                            infoImage={info.metadata.inputMaskUrl}
                        />
                    </ToolInfoItem>
                )}
                {info.metadata?.inputImageFirstUrl && (
                    <ToolInfoItem>
                        <InfoBlock
                            fullWidth
                            isPrimary={false}
                            title={'image 1'}
                            infoImage={info.metadata.inputImageFirstUrl}
                        />
                        {info.metadata?.firstImageStrength && (
                            <ImageInfluenceInfo
                                title={'influence'}
                                value={`${info.metadata.firstImageStrength}%`}
                            />
                        )}
                    </ToolInfoItem>
                )}
                {info.metadata?.inputImageSecondUrl && (
                    <ToolInfoItem>
                        <InfoBlock
                            fullWidth
                            isPrimary={false}
                            title={'image 2'}
                            infoImage={info.metadata.inputImageSecondUrl}
                        />
                        {info.metadata?.firstImageStrength && (
                            <ImageInfluenceInfo
                                title={'influence'}
                                value={`${100 - info.metadata.firstImageStrength}%`}
                            />
                        )}
                    </ToolInfoItem>
                )}
                {info.metadata?.inputReference && (
                    <ToolInfoItem>
                        <InfoBlock
                            fullWidth
                            isPrimary={false}
                            title={'reference image'}
                            infoImage={prefixBaseUrl(info.metadata.inputReference)}
                        />
                        {info.metadata?.referenceWeight && (
                            <ImageInfluenceInfo
                                title={'influence'}
                                value={`${info.metadata.referenceWeight}%`}
                            />
                        )}
                    </ToolInfoItem>
                )}
                {info.metadata?.controlImageUrl && (
                    <ToolInfoItem>
                        <InfoBlock
                            fullWidth
                            isPrimary={false}
                            title={'control image'}
                            infoImage={info.metadata.controlImageUrl}
                        />
                        {info.metadata?.controlInfluence && (
                            <ImageInfluenceInfo
                                title={'influence'}
                                value={`${info.metadata.controlInfluence}%`}
                            />
                        )}
                    </ToolInfoItem>
                )}
                {info.metadata?.influenceImageFirstUrl && (
                    <ToolInfoItem>
                        <InfoBlock
                            fullWidth
                            isPrimary={false}
                            title={'first influence image'}
                            infoImage={info.metadata.influenceImageFirstUrl}
                        />
                        {info.metadata?.influenceWeightFirst && (
                            <ImageInfluenceInfo
                                title={'influence'}
                                value={`${info.metadata.influenceWeightFirst}%`}
                            />
                        )}
                    </ToolInfoItem>
                )}
                {info.metadata?.influenceImageSecondUrl && (
                    <ToolInfoItem>
                        <InfoBlock
                            fullWidth
                            isPrimary={false}
                            title={'second influence image'}
                            infoImage={info.metadata.influenceImageSecondUrl}
                        />
                        {info.metadata?.influenceWeightSecond && (
                            <ImageInfluenceInfo
                                title={'influence'}
                                value={`${info.metadata.influenceWeightSecond}%`}
                            />
                        )}
                    </ToolInfoItem>
                )}
            </ToolInfoContent>
        </ToolInfoWrapper>
    );
};

export default ImageMetadataDetails;

const ToolLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto500',
    color: theme.palette.primary.inactive,
}));

const ToolInfoWrapper = styled(Stack)(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '14px',
}));

const ToolInfoContent = styled(Stack)(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
}));

const ToolInfoItem = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '14px',
}));
